<mat-toolbar color="primary">
  <a
    *ngIf="!windowService.isSmallWidth"
    aria-label="Go back to dashboard."
    routerLink="/"
  >
    Blind Sailing Canada
  </a>
  <a
    *ngIf="windowService.isSmallWidth"
    aria-label="Go back to dashboard."
    routerLink="/"
  >
    BSAC
  </a>
  <div
    *ngIf="!userProfile"
    class="header-controls"
  >
    <button
      mat-icon-button
      (click)="toggleDarkTheme.emit(!isDarkTheme)"
      class="theme-toggle"
      aria-label="Toggle Dark Theme"
      title="Switch to {{isDarkTheme? 'light' : 'dark'}} theme"
    >
      <mat-icon>{{ isDarkTheme ? "dark_mode" : "light_mode" }}</mat-icon>
    </button>
    <a
      class="mat-button"
      title="upcoming sails rss feed"
      aria-label="upcoming sails rss feed"
      href="/feed/upcoming_sails.rss"
    >
      <mat-icon>rss_feed</mat-icon>
    </a>
  </div>
  <div
    *ngIf="userProfile"
    class="header-controls"
  >
    <a
      [routerLink]="viewProfileLink"
      aria-label="your profile"
      title="Click to go to your profile"
    >
      <app-profile-bullet [profile]="userProfile"></app-profile-bullet>
    </a>
    <a
      [routerLink]="viewProfileSettingsLink"
      aria-label="your settings"
      class="mat-button"
      title="Click to go to your settings"
    >
      <mat-icon>settings</mat-icon>
    </a>
    <a
      [routerLink]="viewHelpLink"
      aria-label="help"
      class="mat-button"
      title="Click to go to help"
    >
      <mat-icon>help_outline</mat-icon>
    </a>
    <button
      (click)="toggleDarkTheme.emit(!isDarkTheme)"
      *ngIf="!windowService.isSmallWidth"
      aria-label="Toggle Dark Theme"
      class="theme-toggle"
      mat-icon-button
      title="Switch to {{isDarkTheme? 'light' : 'dark'}} theme"
    >
      <mat-icon>{{ isDarkTheme ? "dark_mode" : "light_mode" }}</mat-icon>
    </button>
    <a
      class="mat-button"
      title="upcoming sails rss feed"
      aria-label="upcoming sails rss feed"
      href="/feed/upcoming_sails.rss"
    >
      <mat-icon>rss_feed</mat-icon>
    </a>
    <button
      (click)="logout()"
      aria-label="log out"
      mat-button
      title="log out"
    >
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</mat-toolbar>
<ng-content></ng-content>
